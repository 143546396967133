import { graphql, Link } from 'gatsby';
import React from 'react';
import HomeNewsletterSection from '../components/homepage/homenewsletter';
import Layout from '../components/layout';
import SEO from '../components/seo';
import globalStyle from '../components/css_modules/global.module.scss'
import blogMain from '../components/css_modules/blog/blogmain.module.scss'
import DownloadableContent from '../components/servicespage/downloadablecontent';
import BlogMainWithSidebar from '../components/blog/blogmainsidebar';
import instaGuide from '../downloads/Instagram_Guide.pdf'

const BlogPage = ({ data }) => (
    <Layout>
        <SEO title="Blog | Digital Community building &amp; nurturing - Digital Madness<" />
        {/* <BlogHeroSection /> */}
        {/* <BlogMainSection /> */}

        {/* <DownloadableContent /> */}

        {/* <BlogMainWithSidebar /> */}

        {/* <section className={blogMain.mainSectionBlog}>
            <div className={globalStyle.container}> */}
        <section className={blogMain.mainSectionBlogSinglePost}>
            <div className={globalStyle.container}>
                <div className={blogMain.blogPostWrapper}>
                    <div className={blogMain.postContent}>
                        <h1 className={globalStyle.heroTitle}>Check out our<br /><label> latest blog posts</label></h1>
                        <ul className={blogMain.listUl + ' ' + blogMain.listUlForMain}>
                            {data.allMarkdownRemark.edges.map(post => (
                                <li className={blogMain.list} key={post.node.id}>
                                    {/* <div className={blogMain.listLeft}>
                                        <img src={post.node.frontmatter.featuredImg} alt={post.node.frontmatter.title + ' image'}></img>
                                    </div> */}
                                    <div className={blogMain.listRight}>
                                        <h3>{post.node.frontmatter.title}</h3>
                                        {/* <small>Posted by {post.node.frontmatter.author} on {' '} {post.node.frontmatter.date}</small> */}
                                        <p>{post.node.frontmatter.previewContent}</p>
                                    </div>
                                    <div className={blogMain.readMoreBtn}>
                                        <Link to={post.node.frontmatter.path}>Read More</Link>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={blogMain.sidebar + ' ' + blogMain.sidebarMainPage}>
                        <ul className={blogMain.sidebarElements}>
                            <li className={blogMain.sidebarSection}>
                                <div className={blogMain.featuredPosts}>
                                    <h2 className={blogMain.sidebarTitles}>FREE DOWNLOADS</h2>
                                    <p>To help shape your digital story, we’ve put together a bumper pack of free downloadable insights.</p>
                                    <br />
                                    <p><center><strong>Includes:</strong></center>
                                        <ul>
                                            <li>Instagram guide for businesses</li>
                                            <li>The truth about digital marketing</li>
                                            <li>Social Selling: The future of sales</li>
                                        </ul>
                                    </p>
                                    {/* Begin Mailchimp Signup Form */}
                                    <link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css" />
                                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t#mc_embed_signup{clear:left; font:18px; width:100%; }\n\t/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.\n\t   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */\n" }} />
                                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t#mc-embedded-subscribe-form input[type=checkbox]{display: inline; width: auto;margin-right: 10px;}\n\t#mergeRow-gdpr {margin-top: 20px;}\n\t#mergeRow-gdpr fieldset label {font-weight: normal;}\n\t#mc-embedded-subscribe-form .mc_fieldset{border:none;min-height: 0px;padding-bottom:0px;border-radius:0; outline: none;}\n" }} />
                                    <div id="mc_embed_signup">
                                        <form action="https://rocks.us19.list-manage.com/subscribe/post?u=c862da19fe3153189b46c8866&id=828ddc94d9" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                                            <div id="mc_embed_signup_scroll">
                                                {/* <input type="email" defaultValue name="EMAIL" className="email" id="mce-EMAIL" placeholder="email address" required /> */}
                                                <input type="email" name="EMAIL" className="email" id="mce-EMAIL" placeholder="Email address" required />
                                                {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                                                <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true"><input type="text" name="b_c862da19fe3153189b46c8866_828ddc94d9" tabIndex={-1} defaultValue /></div>
                                                <div className="clear"><input type="submit" defaultValue="Subscribe" name="subscribe" id="mc-embedded-subscribe" className={globalStyle.mainButton} /></div>
                                            </div>
                                        </form>
                                    </div>
                                    {/*End mc_embed_signup*/}
                                </div>
                            </li>
                            <li className={blogMain.sidebarSection}>
                                <div className={blogMain.sidebarContact}>
                                    <h2>Get in touch</h2>
                                    <p>Get in touch to find out how we can help your business prepare for the future!</p>
                                    <div className={blogMain.sidebarContactButton}><a className={globalStyle.mainButton} href="/contact">Contact us</a></div>
                                </div>
                            </li>
                            <li className={blogMain.sidebarSection}>
                                <h2>Stay Updated</h2>
                                <p>Be the first to receive our latest and greatest insights</p>
                                {/* Begin Mailchimp Signup Form */}
                                <link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css" />
                                <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t#mc_embed_signup{}\n\t/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.\n\t   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */\n" }} />
                                <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t#mc-embedded-subscribe-form input[type=checkbox]{display: inline; width: 250px;margin-right: 10px;}\n\t#mergeRow-gdpr {margin-top: 20px;}\n\t#mergeRow-gdpr fieldset label {font-weight: normal;}\n\t#mc-embedded-subscribe-form .mc_fieldset{border:none;min-height: 0px;padding-bottom:0px;border-radius:0; outline: none;}\n" }} />
                                <div id="mc_embed_signup">
                                    <form action="https://rocks.us5.list-manage.com/subscribe/post?u=b3ac922c4afd7563fb0723e94&amp;id=2496565300" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                                        <div id="mc_embed_signup_scroll">
                                            {/* <input type="email" defaultValue name="EMAIL" className="email" id="mce-EMAIL" placeholder="email address" required /> */}
                                            <input type="email" name="EMAIL" className="email blog-email" id="mce-EMAIL" placeholder="Email address" required />
                                            {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                                            <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true"><input type="text" name="b_b3ac922c4afd7563fb0723e94_2496565300" tabIndex={-1} defaultValue /></div>
                                            <div className="clear"><input type="submit" defaultValue="Subscribe" name="subscribe" id="mc-embedded-subscribe" className={globalStyle.mainButton} /></div>
                                        </div>
                                    </form>
                                </div>
                                {/*End mc_embed_signup*/}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <HomeNewsletterSection />
    </Layout>
)

export const pageQuery = graphql`
    query BlogIndexQuery {
        allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            limit: 1000
            ) {
            edges {
            node {
                id
                frontmatter {
                    path
                    title
                    date
                    author
                    featuredImg
                    previewContent
                    }
                }
            }
        }
    }
`

export default BlogPage

