import React, { Component } from 'react'
import { Link } from "gatsby"
import globalStyle from '../css_modules/global.module.scss'
import dowContent from '../css_modules/downloadablecontent.module.scss'
import servicesPackages from '../css_modules/servicespackages.module.scss'
import instaGuide from '../../downloads/Instagram_Guide.pdf'
import mtoolsCheatSheet from '../../downloads/Maketing_Tools_CheatSheet.pdf'
import dmPrezi from '../../downloads/DM_Truth_about_Digital_Marketing.pdf'

export class DownloadableContent extends Component {
    render() {
        return (
            <section className={dowContent.section}>
                <div className={globalStyle.container}>
                    <h2 className={globalStyle.heroTitle}>From Digital Madness to the world<br /><label>for free!</label></h2>
                    <div className={dowContent.contentWrapper}>
                        <ul>
                            <li className={dowContent.box + ' ' + dowContent.boxInsta}>
                                <div className={dowContent.blueOverlay}></div>
                                <div className={dowContent.boxFace}>
                                    <p>Download our FREE Instagram Guide for Businesses.</p><br />
                                    <p>If you need any more informations, just want to say "hi" or have feedback, don't hesitate to contact us. We're looking forward to hear from you!</p> <br />
                                </div>
                                <div className={dowContent.boxOverlay}>
                                    <center><button><a href={instaGuide} target="_blank" download>download guide</a></button></center>
                                </div>
                            </li>
                            <li className={dowContent.box + ' ' + dowContent.boxCsheet}>
                                <div className={dowContent.blueOverlay}></div>
                                <div className={dowContent.boxFace}>
                                    <p>Download our FREE Marketing Tools CheatSheet.</p><br />
                                    <p>If you need any more informations, just want to say "hi" or have feedback, don't hesitate to contact us. We're looking forward to hear from you!</p> <br />
                                </div>
                                <div className={dowContent.boxOverlay}>
                                    <center><button><a href={mtoolsCheatSheet} target="_blank" download>Download cheetsheet</a></button></center>
                                </div>
                            </li>
                        </ul>
                        <ul>
                            <li className={dowContent.box + ' ' + dowContent.boxPrezi}>
                                <div className={dowContent.blueOverlay}></div>
                                <div className={dowContent.boxFace}>
                                    <p>Download our FREE Instagram Guide for Businesses.</p><br />
                                    <p>If you need any more informations, just want to say "hi" or have feedback, don't hesitate to contact us. We're looking forward to hear from you!</p> <br />
                                </div>
                                <div className={dowContent.boxOverlay}>
                                    <center><button><a href={dmPrezi} target="_blank" download>download presentation</a></button></center>
                                    {/* <center><button><Link to={dmPrezi} download>download presentation</Link></button></center> */}
                                </div>
                            </li>
                            <li className={dowContent.box + ' ' + dowContent.boxSubscription}>
                                <div className={dowContent.blueOverlay}></div>
                                <div className={dowContent.boxFace}>
                                    <p>Stay Updated</p>
                                    <p>Be the first to receive our latest and greatest insights</p>
                                    {/* Begin Mailchimp Signup Form */}
                                    <link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css" />
                                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t#mc_embed_signup{}\n\t/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.\n\t   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */\n" }} />
                                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t#mc-embedded-subscribe-form input[type=checkbox]{display: inline; width: 250px;margin-right: 10px;}\n\t#mergeRow-gdpr {margin-top: 20px;}\n\t#mergeRow-gdpr fieldset label {font-weight: normal;}\n\t#mc-embedded-subscribe-form .mc_fieldset{border:none;min-height: 0px;padding-bottom:0px;border-radius:0; outline: none;}\n" }} />
                                    <div id="mc_embed_signup">
                                        <form action="https://rocks.us5.list-manage.com/subscribe/post?u=b3ac922c4afd7563fb0723e94&amp;id=2496565300" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                                            <div id="mc_embed_signup_scroll">
                                                <input type="email" name="EMAIL" className="email blog-email" id="mce-EMAIL" placeholder="Email address" required />
                                                {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                                                <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true"><input type="text" name="b_b3ac922c4afd7563fb0723e94_2496565300" tabIndex={-1} defaultValue /></div>
                                                <div className="clear"><input type="submit" defaultValue="Subscribe" name="subscribe" id="mc-embedded-subscribe" className={globalStyle.mainButton} /></div>
                                            </div>
                                        </form>
                                    </div>
                                    {/*End mc_embed_signup*/}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section >
        )
    }
}

export default DownloadableContent
